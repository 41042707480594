import React from "react"
import { css } from "@emotion/core"
import Cover from "./cover"
import Nav from "./nav"
import Footer from "./footer"

import "prismjs/plugins/line-numbers/prism-line-numbers.css"
import "katex/dist/katex.min.css"

const Layout = ({ children, location }) => {
  return (
    <>
      {location.pathname === `/` && <Cover />}
      <Nav />
      <div
        css={css`
          width: 800px;
          @media (max-width: 800px) {
            width: 100vw;
          }
          margin: auto;
          color: var(--text-normal-color);
        `}
      >
        {children}
      </div>
      <Footer />
    </>
  )
}

export default Layout
