import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import titleLogoSVG from "../images/logo.svg"

import ScrollDown from "./scroll-down"

const Cover = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "background.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      Tag="div"
      className={className}
      fluid={data.coverImage.childImageSharp.fluid}
      backgroundColor={`var(--cover-background-color);`}
      css={css`
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-size: cover;
        color: #fff;
        box-shadow: 0 0 40px #000;
      `}
    >
      <img
        src={titleLogoSVG}
        alt=""
        css={css`
          width: 800px;
          @media (max-width: 800px) {
            width: 80vw;
          }
          filter: drop-shadow(0 0 10px #000);
        `}
      ></img>
      <div
        css={css`
          display: flex;
          margin-top: 50px;
        `}
      >
        <Link
          to={`/about/`}
          css={css`
            box-sizing: border-box;
            padding: 2px;
            cursor: pointer;
            appearance: none;
            text-decoration: underline;
            transform: skew(-15deg, 0);
            text-align: center;
            font-size: 2rem;
            width: 300px;
            @media (max-width: 800px) {
              font-size: 1rem;
              width: 40vw;
            }
            filter: drop-shadow(0 0 2px #000) drop-shadow(0 0 5px #000);
            font-weight: bold;

            a& {
              color: #fff;
            }

            &:hover {
              background-color: #fff;
              color: #000;
              filter: none;
            }
          `}
        >
          About
        </Link>
        <ScrollDown>Blog</ScrollDown>
      </div>
    </BackgroundImage>
  )
}

export default Cover
