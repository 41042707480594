import React from "react"
import { css } from "@emotion/core"
import { Link, useStaticQuery, graphql } from "gatsby"

const MonthList = () => {
  const months = new Set()
  const result = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
          edges {
            node {
              frontmatter {
                month: date(formatString: "YYYY/MM")
              }
            }
          }
        }
      }
    `
  )
  result.allMarkdownRemark.edges.forEach(({ node }) => {
    months.add(node.frontmatter.month)
  })
  return (
    <ul
      css={css`
        margin: 0 20px;
      `}
    >
      {[...months].map(m => (
        <li
          key={m}
          css={css`
            list-style: none;
            margin: 0;
          `}
        >
          <Link to={`/${m}`}>{m}</Link>
        </li>
      ))}
    </ul>
  )
}

export default MonthList
