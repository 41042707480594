import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, pathname, article }) => {
  const result = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl
          defaultImage
          twitterUsername
        }
      }
    }
  `)
  const seo = {
    title: title
      ? `${title} | ${result.site.siteMetadata.defaultTitle}`
      : result.site.siteMetadata.defaultTitle,
    description: description || result.site.siteMetadata.defaultDescription,
    image: `${result.site.siteMetadata.siteUrl}${image ||
      result.site.siteMetadata.defaultImage}`,
    url: `${result.site.siteMetadata.siteUrl}${pathname || "/"}`,
    twitterUsername: result.site.siteMetadata.twitterUsername,
  }
  return (
    <>
      <Helmet title={seo.title} htmlAttributes={{ lang: `ja` }} defer={false}>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={seo.twitterUsername} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
      </Helmet>
    </>
  )
}

export default SEO
