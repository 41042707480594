import React from "react"
import { css } from "@emotion/core"
import { Link, useStaticQuery, graphql } from "gatsby"
import escapeTagName from "../utils/escapeTagName"

const TagList = () => {
  const result = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
          group(field: frontmatter___tags) {
            fieldValue
          }
        }
      }
    `
  )
  return (
    <ul
      css={css`
        width: 360px;
        @media (max-width: 800px) {
          width: calc(100vw - 120px);
        }
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        margin: 0;
      `}
    >
      {result.allMarkdownRemark.group.map(({ fieldValue }) => (
        <li
          key={escapeTagName(fieldValue)}
          css={css`
            list-style: none;
            margin: 0 15px 0 0;
          `}
        >
          <Link
            to={`/tags/${escapeTagName(fieldValue)}`}
            css={css`
              &::before {
                content: "#";
              }
            `}
          >
            {fieldValue}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default TagList
