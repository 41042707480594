import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import escapeTagName from "../utils/escapeTagName"

const PostItem = ({ title, datetime, date, tags, html, slug, excerpt }) => {
  const htmlExcerpt = html.split("<!--more-->")[0]
  const showReadMore = excerpt && !!html.split("<!--more-->")[1]
  const innerHTML = {
    __html: excerpt ? htmlExcerpt : html,
  }
  return (
    <article
      css={css`
        box-sizing: border-box;
        padding: 40px;
        padding-top: 60px;
        padding-bottom: 20px;
        @media (max-width: 800px) {
          padding: 20px;
          padding-top: 60px;
          padding-bottom: 20px;
        }

        margin-bottom: 60px;
        background-color: var(--post-background-color);
        position: relative;

        blockquote {
          position: relative;
          padding: 10px 15px 10px 50px;
          box-sizing: border-box;
          font-style: italic;
          border: solid 2px #464646;
        }

        blockquote:before {
          display: inline-block;
          position: absolute;
          top: 10px;
          left: 0;
          content: "“";
          font-family: sans-serif;
          color: #77c0c9;
          font-size: 90px;
          line-height: 1;
        }

        blockquote p {
          padding: 0;
          margin: 10px 0;
          line-height: 1.7;
        }

        blockquote cite {
          display: block;
          text-align: right;
          color: #888888;
          font-size: 0.9em;
        }
      `}
    >
      {excerpt ? (
        <Link
          to={slug}
          css={css`
            color: var(--text-title-color);
            text-decoration: none;
          `}
        >
          <h1>{title}</h1>
        </Link>
      ) : (
        <h1>{title}</h1>
      )}
      <time
        dateTime={datetime}
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 150px;
          color: rgba(255, 255, 255, 0.8);
          background-color: #000;
          text-align: center;
          font-size: 14px;
          border-radius: 0 0 10px 0;
        `}
      >
        {date}
      </time>
      <div dangerouslySetInnerHTML={innerHTML} />
      {showReadMore && <Link to={slug}>Read More...</Link>}
      <ul
        css={css`
          list-style: none;
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
        `}
      >
        {tags &&
          tags.map(tag => (
            <li
              key={tag}
              css={css`
                margin-left: 10px;
              `}
            >
              <Link
                to={`/tags/${escapeTagName(tag)}/`}
                css={css`
                  &::before {
                    content: "#";
                  }
                `}
              >
                {tag}
              </Link>
            </li>
          ))}
      </ul>
    </article>
  )
}

export default PostItem
