import React from "react"
import { css } from "@emotion/core"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

const DarkmodeButton = () => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => (
      <div>
        <label htmlFor="darkmode-checkbox">
          <input
            type="checkbox"
            id="darkmode-checkbox"
            onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
            checked={theme === "dark"}
            css={css`
              display: none;
            `}
          />
          <span
            css={css`
              display: block;
              width: 35px;
              height: 35px;
              @media (max-width: 800px) {
                margin: 10px;
              }

              border-radius: 50%;
              cursor: pointer;
              box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.3);
              input[type="checkbox"] + & {
                border: 4px solid #888;
                background: #000;
              }
              input[type="checkbox"]:checked + & {
                border: 4px solid #aaa;
                background: #fff;
              }
            `}
          />
        </label>
      </div>
    )}
  </ThemeToggler>
)

export default DarkmodeButton
