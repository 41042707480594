import React from "react"
import { css } from "@emotion/core"
import { animateScroll } from "react-scroll"

const ScrollDown = ({ children }) => {
  const handleClick = () =>
    animateScroll.scrollTo(window.innerHeight, {
      smooth: true,
      duration: 500,
    })
  return (
    <button
      onClick={handleClick}
      css={css`
        box-sizing: border-box;
        padding: 2px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        appearance: none;
        color: #fff;
        text-decoration: underline;
        transform: skew(-15deg, 0);
        text-align: center;
        font-size: 2rem;
        width: 300px;
        @media (max-width: 800px) {
          font-size: 1rem;
          width: 40vw;
        }
        font-weight: bold;
        filter: drop-shadow(0 0 2px #000) drop-shadow(0 0 5px #000);

        &:hover {
          background-color: #fff;
          color: #000;
          filter: none;
        }
      `}
    >
      {children}
    </button>
  )
}

export default ScrollDown
