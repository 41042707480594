import React from "react"
import { css } from "@emotion/core"
import TagList from "./tag-list"
import MonthList from "./month-list"

const Footer = () => (
  <footer
    css={css`
      width: 800px;
      @media (max-width: 800px) {
        width: 100vw;
      }
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    `}
  >
    <TagList />
    <MonthList />
    <a
      className="twitter-timeline"
      data-width="300"
      data-height="600"
      data-theme="dark"
      href="https://twitter.com/MatchaChoco010?ref_src=twsrc%5Etfw"
    >
      Tweets by MatchaChoco010
    </a>
  </footer>
)

export default Footer
